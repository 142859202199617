import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.scss'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthUserProvider } from './utils/firebase/auth'

// Remove or comment out the unused function
// function scroller(element) {
//   if (element && typeof element === 'object') {
//     element.active = true;
//   } else {
//     console.warn('Attempted to set active on undefined element');
//   }
// }

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthUserProvider>
        <App />
      </AuthUserProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()