import React from 'react'
import { Link } from 'react-router-dom'
import design from '../../../assets/images/design.png'
import hp from '../../../assets/images/hp.png'

import './index.scss'

// Firebase authentication hook to manage user authentication state
import { useAuth } from '../../../utils/firebase/auth'

const Hero = () => {
  // Retrieve the current authenticated user state
  const { authUser } = useAuth()

  return (
    <div className="hero__landing">
      <div className="landing__container">
        <div className="left__landing">
          <div className="landing__content">
            <div className="landing__text">
              <h1>
                Evolving Education with <br />
                <span> DIGITAL LEVERAGE</span>
              </h1>
              <p className="leading-8">
              Bridging educational gaps through technology, by empowering <br/> every student to learn, grow, and succeed.
              </p>
            </div>
            <div className="landing__buttons">
              {/* Dynamic link that changes based on authentication status */}
              <Link to={authUser ? '/dashboard' : '/account/signup'}>
                {authUser ? 'Continue' : 'Join Us'}
              </Link>
              <Link to="/about">Learn More</Link>
            </div>
          </div>
        </div>
        <div className="right__landing">
          {/* Static images for visual appeal */}
          <img src={hp} alt="Hero" className="laptop" />
          <img src={design} alt="Hero" className="laptop__design" />
        </div>
      </div>
    </div>
  )
}

export default Hero
