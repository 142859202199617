import React from 'react'
import BreadCrumb from '../../BreadCrumb'
import './index.scss'

const Resources = function () {
  return (
    <main className="resources">
      <BreadCrumb
        key={Math.random() * 1000}
        title={'My Resources'}
        slugs={[
          {
            display: 'Dashboard',
            to: '/dashboard/home',
            active: false,
          },

          {
            display: 'Resources',
            to: '/dashboard/resources',
            active: true,
          },
        ]}
      ></BreadCrumb>

      <section className="resources-content">
        <div className="container">
          <div className="resources-profile">
            <div className="title"></div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Resources
