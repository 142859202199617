import { collection, getDocs, query, where } from 'firebase/firestore'
import { capitalize } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import laptop from '../../../assets/images/library/laptop1.png'
import Alert from '../../../components/Alert'
import Footer from '../../../components/Footer'
import { categories, levels, subSystems } from '../../../utils/constants'
import { firestore } from '../../../utils/firebase'
import { useAuth } from '../../../utils/firebase/auth'

import {
  faBook,
  faFileAlt,
  faSpinner,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChevronRight, FilePdf } from 'react-bootstrap-icons'

function Library() {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedLevels, setSelectedLevels] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  const [selectedSystems, setSelectedSystems] = useState([])
  const [resources, setResources] = useState([])
  const [filteredResources, setFilteredResources] = useState([])
  const [showSearchResults, setShowSearchResults] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)
  const [loading, setLoading] = useState(true)
  const { authUser } = useAuth()
  const [keywords, setKeywords] = useState('')

  const fetchRandomResources = useCallback(async () => {
    const resourcesRef = collection(firestore, 'resources')
    const q = query(resourcesRef)
    const querySnapshot = await getDocs(q)
    const fetchedResources = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    const shuffled = fetchedResources.sort(() => 0.5 - Math.random())
    setResources(shuffled.slice(0, 10))
    setFilteredResources(shuffled.slice(0, 10))
    setLoading(false)
  }, [])

  useEffect(() => {
    fetchRandomResources()
  }, [fetchRandomResources])

  const handleSearch = async () => {
    if (!authUser) {
      Alert.fire({
        icon: 'warning',
        title: 'Login Required',
        text: 'Please log in to search for resources.',
        showCancelButton: true,
        confirmButtonText: 'Log In',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          // Redirect to login page
          window.location.href = '/account/login'
        }
      })
      return
    }

    const resourcesRef = collection(firestore, 'resources')
    let q = query(resourcesRef)

    if (keywords) {
      const lowercaseKeywords = keywords.toLowerCase().split(' ')
      q = query(q, where('keywords', 'array-contains-any', lowercaseKeywords))
    }

    const querySnapshot = await getDocs(q)
    const searchResults = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    setResources(searchResults)
    setFilteredResources(searchResults)
    setShowSearchResults(true)
    if (searchResults.length === 0) {
      Alert.fire({
        icon: 'info',
        title: 'No Results',
        text: 'No resources were found matching your search.',
      })
    }
  }

  const handleInputChange = (event) => {
    const input = event.target.value
    setKeywords(input)
    if (input === '') {
      setShowSearchResults(false)
      fetchRandomResources()
    }
  }

  const applyFilters = useCallback(() => {
    let filtered = resources
    if (selectedLevels.length > 0) {
      filtered = filtered.filter((resource) =>
        selectedLevels.includes(resource.level)
      )
    }
    if (selectedTypes.length > 0) {
      filtered = filtered.filter((resource) =>
        selectedTypes.includes(resource.resourceType)
      )
    }
    if (selectedSystems.length > 0) {
      filtered = filtered.filter((resource) =>
        selectedSystems.includes(resource.system)
      )
    }
    setFilteredResources(filtered)
  }, [resources, selectedLevels, selectedTypes, selectedSystems])

  useEffect(() => {
    applyFilters()
  }, [applyFilters])

  const handleFilterChange = (filterType, value) => {
    let updatedFilter
    switch (filterType) {
      case 'level':
        updatedFilter = selectedLevels.includes(value)
          ? selectedLevels.filter((item) => item !== value)
          : [...selectedLevels, value]
        setSelectedLevels(updatedFilter)
        break
      case 'type':
        updatedFilter = selectedTypes.includes(value)
          ? selectedTypes.filter((item) => item !== value)
          : [...selectedTypes, value]
        setSelectedTypes(updatedFilter)
        break
      case 'system':
        updatedFilter = selectedSystems.includes(value)
          ? selectedSystems.filter((item) => item !== value)
          : [...selectedSystems, value]
        setSelectedSystems(updatedFilter)
        break
    }
  }

  const handleResourceClick = (resource) => {
    setSelectedResource(resource)
  }

  const closeResourceModal = () => {
    setSelectedResource(null)
  }

  return (
    <div className="overflow-x-hidden">
      <div className="bg-primary text-center mt-24 py-12 flex flex-col lg:flex-row justify-center items-center w-full">
        <div className="flex flex-col lg:flex-row w-full max-w-6xl justify-between items-center px-4 lg:px-8">
          <div className="text-container w-full lg:w-1/2 text-left text-white">
            <h1 className="text-4xl lg:text-5xl font-bold mb-4">
              Get Free Access to Our Courses
            </h1>
            <h2 className="text-2xl lg:text-3xl font-semibold text-orange-500 mb-4">
              ON ALL DEVICES
            </h2>
            <p className="text-lg">
              Access all our free videos and PDF resources
            </p>
          </div>
          <div className="image-container w-full lg:w-1/2 flex justify-center items-center mt-8 lg:mt-0">
            <img
              src={laptop}
              alt="Laptop"
              className="max-w-full h-auto"
              style={{ transform: 'scale(1.1)' }}
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-100 p-8">
        <h1 className="text-4xl lg:text-5xl font-semibold text-orange-500 text-center mb-4">
          Browse Resources
        </h1>
        <p className="text-center text-lg mb-8">
          Explore a wide range of subjects and topics, and find the perfect
          <br /> resource to kickstart your educational journey
        </p>
        <div className="pb-10 flex flex-col items-center">
          <div className="mt-6 flex flex-col sm:flex-row justify-center w-full max-w-2xl mb-4">
            <input
              type="text"
              placeholder="Enter keywords to search resources"
              className="border-2 border-primary rounded-lg px-4 py-3 w-full sm:w-96 mb-2 sm:mb-0"
              value={keywords}
              onChange={(event) => setKeywords(event.target.value)}
            />
            <button
              className="bg-primary text-white px-6 py-3 rounded-lg hover:bg-opacity-80 transition-colors sm:ml-2 w-full sm:w-auto"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
          <div className="flex flex-wrap justify-center gap-4 mb-4">
            <div>
              <h3 className="font-semibold mb-2">Level:</h3>
              {Object.values(levels)
                .flat()
                .map((level) => (
                  <Checkbox
                    key={level}
                    label={level}
                    onChange={() => handleFilterChange('level', level)}
                    checked={selectedLevels.includes(level)}
                  />
                ))}
            </div>
            <div>
              <h3 className="font-semibold mb-2">Type:</h3>
              {Object.values(categories).map((type) => (
                <Checkbox
                  key={type}
                  label={type}
                  onChange={() => handleFilterChange('type', type)}
                  checked={selectedTypes.includes(type)}
                />
              ))}
            </div>
            <div>
              <h3 className="font-semibold mb-2">System:</h3>
              {subSystems.map((system) => (
                <Checkbox
                  key={system}
                  label={system}
                  onChange={() => handleFilterChange('system', system)}
                  checked={selectedSystems.includes(system)}
                />
              ))}
            </div>
          </div>
        </div>

        {loading ? (
          <div className="text-center mt-4 animate__animated animate__fadeIn">
            <div className="flex justify-center items-center mb-4">
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                className="text-primary"
                size="3x"
              />
            </div>
            <h2 className="text-xl font-semibold animate__animated animate__fadeIn">
              Loading resources...
            </h2>
          </div>
        ) : filteredResources.length === 0 ? (
          <div className="text-center mt-4">
            <h2 className="text-xl font-semibold animate__animated animate__bounceIn">
              No results found
            </h2>
            <p className="text-gray-500 mt-2 animate__animated animate__fadeInUp">
              Your search returned no results. Please try different keywords.
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-items-center items-center">
            {filteredResources.map((resource) => (
              <ResourceCard
                key={resource.id}
                resource={resource}
                onClick={handleResourceClick}
              />
            ))}
          </div>
        )}
      </div>

      {selectedResource && (
        <ResourceModal
          resource={selectedResource}
          onClose={closeResourceModal}
        />
      )}

      <Footer />
    </div>
  )
}

const Checkbox = ({ label, onChange, checked }) => (
  <label className="block text-sm">
    <input
      type="checkbox"
      className="form-checkbox h-4 w-4 text-blue-600"
      onChange={onChange}
      checked={checked}
    />
    <span className="ml-2 text-gray-700">{label}</span>
  </label>
)

const ResourceCard = ({ resource, onClick }) => (
  <div
    className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-all duration-300 hover:shadow-lg hover:scale-105 flex flex-col h-full w-full"
    onClick={() => onClick(resource)}
  >
    <div className="h-48 overflow-hidden relative">
      {resource.resourceType === categories.YOUTUBE ? (
        <img
          src={`https://img.youtube.com/vi/${
            resource.resourceUrl.split('v=')[1]
          }/0.jpg`}
          alt={resource.title}
          className="w-full h-full object-cover"
        />
      ) : (
        <div className="w-full h-full bg-gradient-to-br from-blue-100 to-blue-200 flex items-center justify-center">
          <FilePdf className="w-20 h-20 text-blue-500" />
        </div>
      )}
      <div className="absolute top-0 right-0 bg-blue-500 text-white px-2 py-1 text-xs font-semibold rounded-bl-lg">
        {resource.resourceType}
      </div>
    </div>
    <div className="p-4 flex-grow flex flex-col">
      <h3 className="text-md font-semibold mb-2 line-clamp-2 flex-grow">
        {capitalize(resource.title)}
      </h3>
      <p className="text-sm text-gray-600 mb-1">
        <span className="font-medium">Subject:</span> {resource.subject}
      </p>
      <p className="text-sm text-gray-600">
        <span className="font-medium">Level:</span> {resource.level}
      </p>
    </div>
    <div className="bg-gray-100 px-4 py-2 flex items-center justify-between">
      <p className="text-xs text-gray-500">Click to view details</p>
      <ChevronRight className="w-4 h-4 text-gray-400" />
    </div>
  </div>
)

const ResourceModal = ({ resource, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20">
    <div className="bg-white rounded-lg p-6 w-11/12 h-[90vh] flex flex-col shadow-2xl">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-md md:text-xl font-bold text-primary">
          {capitalize(resource.title)}
        </h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 transition-colors"
        >
          <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
        </button>
      </div>
      <div className="bg-gray-100 rounded-lg p-4 mb-4 grid grid-cols-1 gap-2 md:grid-cols-3">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faUser} className="text-primary mr-2" />
          <p className="text-sm text-gray-700">
            Subject: <span className="font-semibold">{resource.subject}</span>
          </p>
        </div>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faFileAlt} className="text-primary mr-2" />
          <p className="text-sm text-gray-700">
            Type: <span className="font-semibold">{resource.resourceType}</span>
          </p>
        </div>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faBook} className="text-primary mr-2" />
          <p className="text-sm text-gray-700">
            Level: <span className="font-semibold">{resource.level}</span>
          </p>
        </div>
      </div>
      <div className="flex-grow overflow-hidden rounded-lg shadow-inner">
        {resource.resourceType === categories.YOUTUBE ? (
          <div className="w-full h-full">
            <ReactPlayer
              url={resource.resourceUrl}
              width="100%"
              height="100%"
              controls={true}
              config={{
                youtube: {
                  playerVars: { modestbranding: 1 },
                },
              }}
            />
          </div>
        ) : (
          <embed
            src={`${resource.resourceUrl}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&download=0&print=0`}
            type="application/pdf"
            width="100%"
            height="100%"
            className="rounded-lg"
          />
        )}
      </div>
    </div>
  </div>
)

export default Library
