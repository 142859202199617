import {
  faBookAtlas,
  faCalendar,
  faHeart,
  faTableColumns,
  faUserCircle,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../Footer'
import DashboardHeader from '../Header'
import DashboardSideNavBar from '../SideNav'

const links = [
  { text: 'Dashboard', link: '/dashboard/home', faIcon: faTableColumns },
  { text: 'Courses', link: '/dashboard/courses', faIcon: faBookAtlas },
  { text: 'My Resources', link: '/dashboard/resources', faIcon: faHeart },
  { text: 'My Profile', link: '/dashboard/profile', faIcon: faUserCircle },
  { text: 'My Schedule', link: '/dashboard/schedule', faIcon: faCalendar },
  { text: 'Instructors', link: '/dashboard/instructors', faIcon: faUserGroup },
]
const Dashboard = function ({ children }) {
  const [sideBarShowing, setSideBarShowing] = useState(false)
  const toggleSidebar = () =>
    setSideBarShowing((sideBarShowing) => !sideBarShowing)

  return (
    <>
      <DashboardHeader onNavToggle={toggleSidebar} />
      <DashboardSideNavBar
        sideBarShowing={sideBarShowing}
        toggleSidebar={toggleSidebar}
        linkItems={links}
      />
      <Outlet></Outlet>
      {children}
      <Footer />
    </>
  )
}

export default Dashboard
