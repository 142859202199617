import { classNames } from '@react-pdf-viewer/core'
import React from 'react'
import community from '../../../assets/images/illustrations/services/diversity-flatline.png'
import expert from '../../../assets/images/illustrations/services/expert.png'
import knowledge from '../../../assets/images/illustrations/services/knowledge-outline.png'
import thesis from '../../../assets/images/illustrations/services/thesis.png'
import interactive from '../../../assets/images/illustrations/services/video-tutorial-monochromatic.png'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'
import './index.scss'

const Services = () => {
  return (
    <>
      <Banner title={'What we Offer.'} />
      <div className="services mx-32">
        <ServiceItem
          image={interactive}
          title={'Interactive Module Learning'}
          description={
            'Engage with dynamic lessons, quizzes, and multimedia content for a fun and effective learning experience.'
          }
        ></ServiceItem>
        <ServiceItem
          image={expert}
          title={'Expert Instructors'}
          description={
            'Learn from industry professionals who bring real-world insights to your education.'
          }
          reverse
        />

        <ServiceItem
          image={knowledge}
          title={'Personalized Learning Path'}
          description={
            'Customize your learning journey with assessments and tailored recommendations to achieve your goals efficiently'
          }
        />

        {/* <ServiceItem
          image={expert}
          title={'Expert Instructors'}
          description={
            'Learn from industry professionals who bring real-world insights to your education.'
          }
          reverse
        /> */}
        <ServiceItem
          image={thesis}
          title={'On-the-Go Learning'}
          description={
            'Access your courses on any device, anytime, anywhere for seamless learning.'
          }
          reverse
        />
        <ServiceItem
          image={community}
          title={'Community and Peer Interaction'}
          description={
            'Connect with a diverse community of learners, engage in discussions, and collaborate for a richer educational experience.'
          }
        />
        {/* <ServiceItem
          image={expert}
          title={'On-the-Go Learning'}
          description={
            'Access your courses on any device, anytime, anywhere for seamless learning.'
          }
          reverse
        /> */}
      </div>

      <div className="cta py-16 bg-accent text-primary">
        <div className="container px-auto text-center">
          <h3 className="text-2xl font-semibold tracking-wide py-4">
            Get Started With <bold>KET Academy</bold> Today
          </h3>
          <p className="text-md font-normal my-8">
            Create an account to access our full array of online courses,
            resources and academic support.
          </p>
          <button className="bg-primary px-16 py-8 text-white animate-pulse ">
            Get Started
          </button>
        </div>
      </div>
      <Footer />
    </>
  )
}

const ServiceItem = ({ image, title, description, reverse }) => {
  return (
    <div className="service bg-white rounded-sm shadow-sm p-8 mb-8">
      <div
        className={classNames({
          'flex flex-row py-4 px-12 text-center justify-center items-center': true,
          'flex-row-reverse': reverse,
        })}
      >
        <div className="illustration flex-1">
          <img
            className="w-full h-48 object-contain"
            src={image}
            alt="illustration"
          />
        </div>
        <div className="content flex-2 flex justify-center items-start flex-col ml-4">
          <h3 className="text-xl font-bold py-4">{title}</h3>
          <p className="text-md w-full overflow-hidden text-gray-600">
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Services
