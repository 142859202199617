import React from 'react'
import './index.scss'
import * as BoostrapIcon from 'react-bootstrap-icons'

const WhyUs = () => {
  return (
    <section className="whyus-section py-8 block relative h-auto">
      <div>
        <h3 className="font-bold text-2xl text-center text-primary-alt">
          Why Choose Us.
        </h3>
      </div>
      <div className="reasons my-8 flex flex-row justify-between flex-wrap">
        <div className="row flex flex-row justify-around items-center w-100">
          <div className="reason flex flex-row justify-center m-4 ">
            <span className="icon mr-4 flex  justify-center items-center rounded-lg">
              <BoostrapIcon.PersonCheck className="text-6xl text-primary" />
            </span>
            <div>
              <h3 className="text-left text-xl font-semibold my-1">
                Expert Tutors
              </h3>
              <p className="text-neutral-1 align-text-bottom font-light">
              Learn from experienced teachers and ambassadors 
              who are committed to making education accessible, fun and engaging. 
              
              </p>
            </div>
          </div>
          <div className="reason flex flex-row justify-center m-4">
            <span className="icon mr-4 flex  justify-center items-center rounded-lg">
              <BoostrapIcon.Book className="text-6xl text-primary" />
            </span>
            <div>
              <h3 className="text-left text-xl font-semibold my-1">
                Expert Course Material
              </h3>
              <p className="text-neutral-1 align-text-bottom font-light">
              Access carefully designed, high-quality study materials designed to help you excel academically and build 
              the skills needed for future success.
              </p>
            </div>
          </div>
        </div>
        <div className="row flex flex-row justify-around items-center">
          <div className="reason flex flex-row justify-center items-center m-4">
            <span className="icon mr-4 flex  justify-center items-center rounded-lg">
              <BoostrapIcon.PersonVideo3 className="text-6xl text-primary" />
            </span>
            <div>
              <h3 className="text-left text-xl font-semibold my-1">
                Real-time Lectures
              </h3>
              <p className="text-neutral-1 align-text-bottom font-light">
              Participate in real-time, interactive online classes that make learning engaging. 
              you can ask questions, share insights, and get instant feedback—all from the convenience of your location.
              </p>
            </div>
          </div>
          <div className="reason flex flex-row justify-center m-4">
            <span className="icon mr-4 flex justify-center items-center rounded-lg">
              <BoostrapIcon.ArrowLeftRight className="text-6xl text-primary" />
            </span>
            <div>
              <h3 className="text-left text-xl font-semibold my-1">
                Counseling, Mentorship and Orientation
              </h3>
              <p className="text-neutral-1 align-text-bottom font-light">
              Receive personal support, tailored guidance, and career mentorship. 
              We help you overcome challenges, align your strengths with your academic goals, and create a clear path toward future success.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials flex flex-row overflow-x-auto px-0 "></div>
    </section>
  )
}

export default WhyUs
