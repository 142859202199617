import {
  faArrowLeft,
  faArrowRight,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState, useCallback } from 'react'

// Stylesheet import
import './index.scss'

// Testimonials component definition
const Testimonials = () => {
  // Mock data for testimonials
  const mockdata = [
    {
      stars: 4,
      text: 'I applied as an ambassador to KET Academy to give back and help students facing the same challenges I faced, to be the hand as KET Academy granted me and to impact thousands of people which are academically hungry. From this, I can proudly say, Thanks to KET Academy and dedicate my academic success to you',
      name: 'Kamaha Ronald Parfait',
      school: 'University Student',
    },
    {
      stars: 5,
      text: 'I want to thank KET academy for the excellent assistance offered last year in preparation for my GCE A/L especially during my practical session. The YouTube videos really made my last minute revision worth it. Above all KET academy doesn’t ask for a dime for using time to help GCE candidates.',
      name: 'Enangue N. Catherine',
      school: 'Univ. of Buea, FHS',
    },
    {
      stars: 4,
      text: 'KET academy teaches its teachers. My experience with KET academy as an ambassador and at the same time a student has been truly rich. KET academy shaped me and taught me how to organize my tasks as I created time to cooperate with other students as well as with other ambassadors',
      name: 'Tonlie Alian',
      school: 'G.B.H.S Etoug-Egbe, USS',
    },
    {
      stars: 4,
      text: 'Being a KET Ambassador and a student has given me an overview of what living for others looks like, what giving back to society entails and an additional feeling of selflessness.',
      name: 'Berinyuy A. Vanessa',
      school: 'University Student',
    },
    {
      stars: 5,
      text: 'Being a KET ambassador has helped me work as a team with other youth sharing the same passion and desire to help students. In so doing I have had the opportunity to be a leader, and learn how to manage people who have different views to work as one with a higher efficiency',
      name: 'Tasang Lesly',
      school: 'University Student.',
    },
    {
      stars: 4,
      text: 'I learned a lot from KET academy while preparing to write the GCE. I received much needed assistance and resources: past questions, solution and information. I became an ambassador so I can impact other students in the same way.',
      name: 'Nkengbeza Derick',
      school: 'Prospective Univ. Student',
    }
  ]
  const testimonialBox = useRef(null)
  const [testimonials] = useState(mockdata)

  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to handle scrolling to the next or previous testimonial
  const scrollToNextPrev = useCallback((direction) => {
    const testimonialsCount = testimonials.length;
    const itemWidth = testimonialBox.current?.firstChild?.offsetWidth || 400; // Dynamically get the width of a testimonial or default to 400px
    const totalWidth = itemWidth * testimonialsCount;

    let nextItemPosition = (testimonialBox.current?.scrollLeft || 0) + direction * itemWidth;

    // Wrap around logic for infinite scrolling
    if (nextItemPosition >= totalWidth) {
      nextItemPosition = 0; // Reset to start if moving right from the last item
    } else if (nextItemPosition < 0) {
      nextItemPosition = totalWidth - itemWidth; // Move to the last item if moving left from the first item
    }

    testimonialBox.current?.scrollTo({
      left: nextItemPosition,
      behavior: 'smooth',
    });

    // Update current index for active testimonial
    setCurrentIndex((prevIndex) => {
      return (prevIndex + direction + testimonialsCount) % testimonialsCount;
    });
  }, [testimonials.length]);

  // Effect to auto-scroll testimonials every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      scrollToNextPrev(1);
    }, 5000);

    return () => clearInterval(interval);
  }, [scrollToNextPrev]);

  return (
    <section className="testimonials-section py-8 bg-primary block relative h-auto">
      <div>
        <h3 className="font-bold text-2xl text-center text-secondary">
          What Our Students Say
        </h3>
      </div>

      <div
        className="testimonials flex flex-row overflow-x-auto px-0"
        ref={testimonialBox}
      >
        {/* Render testimonials twice for seamless infinite scrolling */}
        {[...testimonials, ...testimonials].map(({ stars, text, name, school }, i) => (
          <Testimonial
            stars={stars}
            text={text}
            name={name}
            school={school}
            key={`testimonial-${i}`}
            active={i % testimonials.length === currentIndex} // Adjust active logic for duplicated items
          />
        ))}
      </div>
      <div className="controls text-center min-w-min mx-auto my-4">
        
        <button
          className="inline-flex w-10 h-10 p-3 mr-4 justify-center align-middle bg-secondary text-accent rounded-full shadow-xl"
          onClick={() => scrollToNextPrev(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        {testimonials.map((_, i) => (
          <span
            key={i}
            className={`inline-block w-3 h-3 mx-2 rounded-full ${
              i === currentIndex ? 'bg-accent' : 'bg-secondary'
            } my-auto`}
          ></span>
        ))}

        <button
          className="inline-flex w-10 h-10 p-3 ml-4 justify-center align-middle bg-secondary text-accent rounded-full shadow-xl"
          onClick={() => scrollToNextPrev(1)}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </section>
  )
}

const Testimonial = ({ stars, text, name, school, active }) => {
  return (
    <div className={`bg-secondary py-2 px-4 testimonial ${active ? 'active' : ''}`}>
      <div className="stars my-2 flex">
        {Array.from({ length: stars }, (_, i) => (
          <FontAwesomeIcon
            icon={faStar}
            className="text-accent mr-1"
            key={i}
          />
        ))}
        {Array.from({ length: 5 - stars }, (_, i) => (
          <FontAwesomeIcon
            icon={faStar}
            className="text-neutral-alt-3 mr-1"
            key={i}
          />
        ))}
      </div>
      <p className="text">{text}</p>
      <h5 className="mt-2 text-neutral-alt-2 font-bold text-right">{name}</h5>
      <h6 className="font-normal text-right">{school}</h6>
    </div>
  )
}
export default Testimonials
