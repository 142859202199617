import React, { useEffect } from 'react'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { Navbar } from './components'
import AdminDashboard from './components/AdminDasboard'
import Dashboard from './components/StudentDashboard'
import Donations from './pages/site/Donations'
import Library from './pages/site/Library'

import {
  AboutUs,
  GalleryPage,
  HomePage,
  LoginPage,
  NotFound404Page,
  Services,
  SignUpPage,
  Team,
} from './pages'

import './app.scss'
import { AdminDashboardHome } from './pages/admin'
import {
  Courses,
  DashboardHome,
  Profile,
  Resources,
  Schedule,
} from './pages/students'

//React toastify config
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { passwordlessSignin } from './utils/firebase/auth'

let isAuthenticated = false
const App = () => {
  const navigate = useNavigate()
  useEffect(() => {
    // console.log('checking for signin')
    const checkAuth = async () => {
      if (!isAuthenticated) {
        isAuthenticated = true
        const signedIn = await passwordlessSignin()
        if (signedIn) {
          navigate('/dashboard/home')
        }
      }
    }
    checkAuth()
  }, [navigate])
  const WithNavBar = () => {
    return (
      <>
        <Navbar />
        <Outlet />
      </>
    )
  }
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<WithNavBar />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="services" element={<Services />} />
          <Route path="team" element={<Team />} />
          <Route path="library" element={<Library />} />
          <Route path="testimonials" />
          <Route path="donations" element={<Donations />} />
          <Route path="tos" />
          <Route path="privacy-policy" />
          <Route path="gallery" element={<GalleryPage />} />
          <Route path="reports" />
          <Route path="donate" element={<Donations />} />
          <Route path="*" element={<NotFound404Page />} />
        </Route>

        <Route path="account">
          <Route index element={<NotFound404Page />} />
          <Route path="signup" element={<SignUpPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<NotFound404Page />} />
        </Route>

        <Route path="dashboard" element={<Dashboard />}>
          <Route index element={<DashboardHome />} />
          <Route path="home" element={<DashboardHome />} />
          <Route path="courses" element={<Courses />} />
          <Route path="resources" element={<Resources />} />
          <Route path="profile" element={<Profile />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="instructors" element={<></>} />
          <Route path="*" element={<NotFound404Page />} />
        </Route>

        {/* Administration routes */}
        <Route path="admin/dashboard" element={<AdminDashboard />}>
          <Route index element={<AdminDashboardHome />} />
          <Route path="courses" element={<></>} />
          <Route path="resources" element={<></>} />
          <Route path="profile" element={<Profile />} />
          <Route path="schedule" element={<></>} />
          <Route path="instructors" element={<></>} />
          <Route path="*" element={<NotFound404Page />} />
        </Route>
        <Route path="*" element={<NotFound404Page />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ width: 'min(90%, 512px)', margin: '1rem' }}
        theme="light"
      />
    </div>
  )
}

export default App
