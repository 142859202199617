import React from 'react'
import BreadCrumb from '../../BreadCrumb'
import './index.scss'

const Courses = function () {
  return (
    <main className="courses">
      <BreadCrumb
        key={Math.random() * 1000}
        title={'My Courses'}
        slugs={[
          {
            display: 'Dashboard',
            to: '/dashboard/home',
            active: false,
          },

          {
            display: 'Courses',
            to: '/dashboard/courses',
            active: true,
          },
        ]}
      ></BreadCrumb>

      <section className="courses-content">
        <div className="container">
          <div className="courses-profile">
            <div className="title"></div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Courses
