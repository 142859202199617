import {
  faBars,
  faCircleDollarToSlot,
  faX,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { BoxArrowInRight } from 'react-bootstrap-icons'
import { Link, NavLink } from 'react-router-dom'
import logo from '../../assets/images/academy/logo.png'
import { routes } from '../../routes'
import './styles.scss'

// Navbar component definition
const Navbar = () => {
  // State to handle if the header has been scrolled past a certain point
  const [headerScrolled, setHeaderScrolled] = useState(false)
  // State to check if the viewport is mobile size
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960)
  const [navIsOpen, setNavIsOpen] = useState(false)

  // Effect for handling scroll and resize events
  useEffect(() => {
    const onScroll = (e) => setHeaderScrolled(window.scrollY > 140)
    const onResize = (e) => setIsMobile(window.innerWidth < 960)

    document.addEventListener('scroll', onScroll)
    window.addEventListener('resize', onResize)
    return () => {
      document.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onResize)
    }
  }, [])

  // Render the navigation bar
  return (
    <nav
      className={`navbar__home ${
        !isMobile && headerScrolled ? 'nav-scrolled' : ''
      }`}
    >
      <Link to="/">
        <div className="logo">
          <img src={logo} alt="KET Academy Logo" />
        </div>
      </Link>

      <div
        className={`nav-items ${isMobile ? 'nav-mobile' : ''} ${
          navIsOpen ? 'show' : 'hide'
        }`}
      >
        <div className="donate">
          <Link to="/donate">
            <button>
              <FontAwesomeIcon icon={faCircleDollarToSlot} /> Donate
            </button>
          </Link>
        </div>
        <ul className="routes">
          {routes.map((route, index) => (
            <li key={index}>
              <NavLink
                to={route.path}
                className={({ isActive }) =>
                  `nav-link ${isActive ? 'active' : ''}`
                }
              >
                {route.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="cta">
          <Link to="/account/login">
            <button title="Sign out">
              <BoxArrowInRight className="text-xl font-black outline-2 inline mr-2" />
              Login
            </button>
          </Link>
        </div>
      </div>
      <div className={`mobile-toggle ${isMobile ? 'show' : 'hide'}`}>
        <button onClick={(_) => setNavIsOpen((navIsOpen) => !navIsOpen)}>
          <FontAwesomeIcon icon={navIsOpen ? faX : faBars} />
        </button>
      </div>
    </nav>
  )
}

export default Navbar
