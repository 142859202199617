import React from 'react'
import * as BoostrapIcon from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'
import './index.scss'

// Import images
import AnushkaImage from '../../../assets/images/people/Anushka.jpeg'
import FillerImage from '../../../assets/images/people/filler.png'
import GlenImage from '../../../assets/images/people/glen.png'
import KinloImage from '../../../assets/images/people/ket.jpeg'
import TasangImage from '../../../assets/images/people/TasangLesley.jpeg'
import VanessaImage from '../../../assets/images/people/VanessaLogan.jpg'
import WinnieImage from '../../../assets/images/people/Winnie-Vallonia.jpeg'

// Main Team component
const Team = () => {
  const coreTeamMembers = [
    {
      name: 'Kinlo Ephraim T.',
      title: 'Founder',
      education: 'Constructor University',
      image: KinloImage,
    },
    {
      name: 'Kamaha Ronald P.',
      title: 'Operations',
      education: 'USIU',
      image: FillerImage,
    },
    {
      name: 'Niassan K. Anushka',
      title: 'Communications',
      education: 'AUB',
      image: AnushkaImage,
    },
    {
      name: 'Kumo Walters',
      title: 'Finances',
      education: 'BA Economics',
      image: FillerImage,
    },
    {
      name: 'Glen Y. Nfor',
      title: 'Technical',
      education: 'Princeton University',
      image: GlenImage,
    },
  ]

  const ambassadors = [
    {
      name: 'Vanessa Logan A.',
      education: 'Ashesi',
      title: 'HOD, ICT',
      departments: ['ICT', 'Chem'],
      image: VanessaImage,
    },
    // {
    //   name: 'Mbwaye Roy N.',
    //   education: 'Prospective University Student',
    //   title: 'HOD, Computer Science',
    //   departments: ['CSC', 'Math'],
    //   image: FillerImage,
    // },
    {
      name: 'Nfor Glen Y.',
      education: 'Princeton University',
      title: 'HOD Computer Science',
      departments: ['CSC', 'Math', 'Phy'],
      image: GlenImage,
    },
    {
      name: 'Nna Winnie Vallonia',
      education: 'Northwestern University',
      title: 'Media Manager',
      departments: ['Chem', 'Counselling'],
      image: WinnieImage,
    },
    {
      name: 'Tasang Lesley N.',
      education: 'Student at NASPW Yaounde',
      title: 'HOD Physics',
      departments: ['Phy', 'Math'],
      image: TasangImage,
    },
    {
      name: 'Tonlieu N. Alain Jorel',
      education: 'Open Dreams',
      title: "Math Dep't",
      departments: ['Math', 'Bio'],
      image: FillerImage,
    },
  ]

  return (
    <>
      {/* Banner component with title */}
      <Banner
        title="The KET Academy Team"
        content="We are a group of visionary selfless individuals who wish to see a positive change in our education system and promote development in our country, Cameroon. To this cause, we dedicate our resources and efforts to simplifying academics for students all over Cameroon."
      />

      <section className="py-12 px-2 sm:px-4 lg:px-6">
        <div className="max-w-7xl mx-auto">
          {/* Core Team section */}
          <div className="mb-16">
            <h2 className="text-3xl font-bold text-center text-primary mb-8">
              Core Team
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4">
              {coreTeamMembers.map((member, index) => (
                <CoreTeamCard key={index} {...member} />
              ))}
            </div>
          </div>

          {/* Ambassadors section */}
          <div className="mb-16">
            <h2 className="text-3xl font-bold text-center text-primary mb-8">
              Our Ambassadors
            </h2>
            <div className="flex flex-wrap justify-center items-center gap-2">
              {ambassadors.map((member, index) => (
                <MemberCard key={index} {...member} />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Footer component */}
      <Footer />
    </>
  )
}

// CoreTeamCard component
const CoreTeamCard = function ({ name, title, education, role, image }) {
  return (
    <div className="w-full bg-white shadow-md rounded-md overflow-hidden m-2">
      <div className="p-5">
        <div className="avatar mb-4">
          <img
            src={image}
            alt={`${name} - Core team member`}
            className="w-full h-48 object-cover rounded"
          />
        </div>
        <div className="info text-center">
          <h4 className="text-primary text-lg mb-2">{name}</h4>
          <p className="text-gray-600">{education}</p>
          <p className="text-gray-600 mb-2">{role}</p>
          <hr className="w-16 my-2 h-1 bg-accent-alt mx-auto"></hr>
          <span className="font-secondary text-md block mb-3">{title}</span>
          <div className="contacts flex justify-center space-x-3">
            <Link to="https://www.twitter.com/home" className="twitter">
              <BoostrapIcon.Twitter className="text-xl text-primary" />
            </Link>
            <Link
              to="https://www.linkedin.com/in/kinlo-ephraim-tangiri-a70113218/"
              className="linkedin"
            >
              <BoostrapIcon.Linkedin className="text-xl text-primary" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

// MemberCard component for individual team members
const MemberCard = function ({ name, education, title, departments, image }) {
  return (
    <div className="member">
      <div className="container">
        {/* Member's avatar */}
        <div className="avatar mx-2">
          <img src={image} alt={`${name} - Ambassador`} />
        </div>

        {/* Member's information */}
        <div className="info mx-2">
          {/* Name */}
          <h4 className="text-primary font-bold mb-1">{name}</h4>

          {/* Education details */}
          <p>{education}</p>

          {/* Decorative horizontal line */}
          <hr className="w-16 my-2 h-1 bg-accent-alt outline-none border-none"></hr>

          {/* Position */}
          <span className="text-primary my-2">{title}</span>

          {/* Department tags */}
          <div className="depts my-2 mx-auto">
            {departments.map((dept, index) => (
              <span
                key={index}
                className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-4 py-1 rounded cursor-pointer"
              >
                {dept}
              </span>
            ))}
          </div>

          {/* Social media links */}
          <div className="contacts">
            {/* Twitter */}
            <Link to="https://www.twitter.com/home" className="twitter">
              <BoostrapIcon.Twitter className="text-2xl text-primary" />
            </Link>
            {/* Facebook */}
            <Link
              to="https://www.facebook.com/kinlo.ephraim/"
              className="facebook"
            >
              <BoostrapIcon.Facebook className="text-2xl text-primary" />
            </Link>
            {/* Instagram */}
            <Link
              to="https://www.instagram.com/kinloephraim/"
              className="instagram"
            >
              <BoostrapIcon.Instagram className="text-2xl text-primary" />
            </Link>
            {/* LinkedIn */}
            <Link
              to="https://www.linkedin.com/in/kinlo-ephraim-tangiri-a70113218/"
              className="linkedin"
            >
              <BoostrapIcon.Linkedin className="text-2xl text-primary" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
