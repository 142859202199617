import {
  faBullseye,
  faExchange,
  faEye,
  faHeartPulse,
  faRegistered,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'
import './index.scss'

const AboutUs = () => {
  return (
    <>
      <Banner
        title={'What We Are.'}
        content={`KET Academy is a non-profit organization dedicated to reforming education in Cameroon. We orient, assist, mentor, and support students in their academic pursuits to benefit the community.`}
      />

      <div className="mvp mt-12 mb-4 mx-8">
        <div className="flex flex-col lg:flex-row justify-center items-center gap-4">
          <div className="item flex lg:w-[420px] bg-primary rounded-xl w-full">
            <div className="left flex items-center justify-center px-12 py-4 border-r-2 border-secondary w-1/5">
              <FontAwesomeIcon
                icon={faBullseye}
                className="h-12 font-extrabold text-accent"
              />
            </div>
            <div className="right block px-4 py-4 w-4/5">
              <h3 className="text-accent text-xl pb-2 font-bold">Our Mission</h3>
              <p className="text-secondary">
                Our mission is to empower youths with the skills and resources needed to transform education and make it accessible to all students.
              </p>
            </div>
          </div>

          <div className="item flex lg:w-[420px] bg-primary rounded-xl w-full ">
            <div className="left flex items-center justify-center px-12 py-4 border-r-2 border-secondary w-1/5">
              <FontAwesomeIcon
                icon={faEye}
                className="h-12 font-extrabold text-accent"
              />
            </div>
            <div className="right block px-4 py-4 w-4/5">
              <h3 className="text-accent text-xl pb-2 font-bold">Our Vision</h3>
              <p className="text-secondary">
                We envision a future where every student, regardless of their circumstances, has equal access to quality education and mentorship.
              </p>
            </div>
          </div>

          <div className="item flex lg:w-[420px] bg-primary rounded-xl w-full ">
            <div className="left flex items-center justify-center px-12 py-4 border-r-2 border-secondary w-1/5">
              <FontAwesomeIcon
                icon={faHeartPulse}
                className="h-12 font-extrabold text-accent"
              />
            </div>
            <div className="right block px-4 py-4 w-4/5">
              <h3 className="text-accent text-xl pb-2 font-bold">Our Passion</h3>
              <p className="text-secondary">
              Lies in creating an inclusive educational environment where every student feels valued and supported.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Enhanced Our History as timeline with UI effects */}
      <div className="py-4 flex flex-col justify-center sm:py-12 my-4">
        <h1 className="text-4xl text-primary text-center font-extrabold my-2">
          Our History
        </h1>
        <div className="py-3 sm:max-w-4xl sm:mx-auto w-full px-3">
          <div className="relative text-gray-700 antialiased text-sm font-semibold">
            <div className="sm:block w-1 bg-primary absolute h-full left-1/2 transform -translate-x-1/2 z-[-99]"></div>

            {/* Timeline events with hover effects */}
            <div className="mt-6 sm:mt-0 sm:mb-12 w-full">
              <div className="flex flex-col sm:flex-row items-center hover:scale-105 transition-transform duration-300">
                <div className="flex justify-start w-full mx-auto items-center">
                  <div className="w-full sm:w-1/2 sm:pr-8">
                    <div className="p-4 bg-white rounded shadow-md">
                      <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                        <span className="bg-pink-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 font-secondary">
                          August 2019
                        </span>
                      </h3>
                      Conceptualization of the idea for this platform from the founder's interaction with internally displaced students in high school.
                    </div>
                  </div>
                </div>
                <div className="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faRegistered}
                    className="h-5 text-primary"
                  />
                </div>
              </div>

              {/* Additional timeline events with hover effects */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex flex-col sm:flex-row items-center hover:scale-105 transition-transform duration-300">
                  <div className="flex justify-end w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div className="p-4 bg-white rounded shadow">
                        <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                          <span className="bg-blue-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 font-secondary">
                            October 2020
                          </span>
                        </h3>
                        Creation of various study forums, YouTube channel and running the organization solo by the founder
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faExchange}
                      className="h-5 text-primary z-10 block relative"
                    />
                  </div>
                </div>
              </div>

              {/* More timeline events can be added here */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex flex-col sm:flex-row items-center hover:scale-105 transition-transform duration-300">
                  <div className="flex justify-start w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pr-8">
                      <div className="p-4 bg-white rounded shadow-md">
                        <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                          <span className="bg-pink-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 font-secondary">
                            November 2021
                          </span>
                        </h3>
                        Launching the first batch of ambassadors(calling on youths with similar passion to support in the vision)
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faRegistered}
                      className="h-5 text-primary"
                    />
                  </div>
                </div>
              </div>

              {/* More timeline events can be added here */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex flex-col sm:flex-row items-center hover:scale-105 transition-transform duration-300">
                  <div className="flex justify-end w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div className="p-4 bg-white rounded shadow">
                        <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                          <span className="bg-blue-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 font-secondary">
                            December 2022
                          </span>
                        </h3>
                        Launching the second batch of ambassadors (Integrating training program to empower youths with EdTec skills to enhance their contributions)
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faExchange}
                      className="h-5 text-primary z-10 block relative"
                    />
                  </div>
                </div>
              </div>

              {/* More timeline events can be added here */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex flex-col sm:flex-row items-center hover:scale-105 transition-transform duration-300">
                  <div className="flex justify-start w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pr-8">
                      <div className="p-4 bg-white rounded shadow-md">
                        <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                          <span className="bg-pink-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 font-secondary">
                            January 2023
                          </span>
                        </h3>
                        KET Academy officially being registered as an organization in Cameroon with national and international partners
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faRegistered}
                      className="h-5 text-primary"
                    />
                  </div>
                </div>
              </div>

              {/* More timeline events can be added here */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex flex-col sm:flex-row items-center hover:scale-105 transition-transform duration-300">
                  <div className="flex justify-end w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div className="p-4 bg-white rounded shadow">
                        <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                          <span className="bg-blue-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 font-secondary">
                            November 2024
                          </span>
                        </h3>
                        Launching the official e-learning platform
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faExchange}
                      className="h-5 text-primary z-10 block relative"
                    />
                  </div>
                </div>
              </div>

              {/* End of timeline */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AboutUs
